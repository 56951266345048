<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'documents' })"
    >
      المستندات
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">البيانات الشخصية</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الاسم بالعربي"
                    label="الاسم بالعربي"
                    v-model="documents.ar_title"
                    id="ar_title"
                    name="ar_title"
                    validate="required"
                    :disabled="$route.name === 'showDocuments'"
                  ></input-form>
                  <input-form
                    placeholder="الاسم باللغة الانجليزية"
                    label="الاسم باللغة الانجليزية"
                    v-model="documents.en_title"
                    id="en_title"
                    name="en_title"
                    validate="required"
                    :disabled="$route.name === 'showDocuments'"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" class="mb-3">
                  <select-form
                    :options="fileTypeOptions"
                    label="نوع الملف"
                    v-model="documents.type"
                    placeholder="نوع الملف"
                    name="type"
                    validate="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-3">
                    <ValidationProvider name="مطلوبة من" rules="required" v-slot="{ errors }">
                      <b-form-group label="مطلوب من">
                        <v-select
                          multiple
                          v-model="documents.required_for"
                          class="mt-3"
                          :class="{ 'is-invalid': errors.length > 0 }"
                          :options="['doctors', 'clinics']"
                          dir="rtl"
                          placeholder="مطلوبة من"
                          :searchable="false"
                          name="required_for"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-3">
                  <b-form-group
                    label="ضروري/اختياري"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio
                      v-model="documents.is_required"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="0"
                      >اختياري</b-form-radio
                    >
                    <b-form-radio
                      v-model="documents.is_required"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="1"
                      >اجباري</b-form-radio
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="$route.name !== 'showDocuments'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >{{ buttonText }}</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../../services/index'
import commonMixin from '@/mixins/commonMixin'

export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      loading: false,
      loadingSubmit: false,
      documents: {
        ar_title: '',
        en_title: '',
        type: '',
        required_for: '',
        is_required: ''
      },
      fileTypeOptions: [
        { value: 'image', text: 'Image' },
        { value: 'pdf', text: 'Pdf' },
        { value: 'word_document', text: 'Word Document' }
      ]
    }
  },
  computed: {
    buttonText () {
      return this.$route.name === 'editDocuments' ? 'تعديل' : 'اضافة'
    }
  },
  methods: {
    getdocuments () {
      api.getDocument(this.$route.params.id).then((res) => {
        this.documents = res.data
      })
    },
    onSubmit () {
      if (this.$route.name === 'editDocuments') {
        this.editDocument()
      } else {
        this.loadingSubmit = true
        api
          .postDocument({
            ar_title: this.documents.ar_title,
            en_title: this.documents.en_title,
            type: this.documents.type,
            required_for: this.documents.required_for,
            is_required: this.documents.is_required === 1
          })
          .then((res) => {
            core.showSnackbar('success', 'تم اضافة ')
            this.loadingSubmit = false
            this.$router.push({ name: 'documents' })
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },
    editDocument () {
      this.loadingSubmit = true
      api
        .editDocument(this.$route.params.id, {
          ar_title: this.documents.ar_title,
          en_title: this.documents.en_title,
          type: this.documents.type,
          required_for: this.documents.required_for,
          is_required: this.documents.is_required === 1
        })
        .then(() => {
          this.loadingSubmit = false
          core.showSnackbar('success', 'تم تعديل بنجاح')
          this.$router.push({ name: 'documents' })
        })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getdocuments()
    }
  }
}
</script>
  <style scoped>
.setting-box-title {
  min-width: 140px;
}
.setting-box-inner {
  margin-bottom: 30px;
}
</style>
